window.cancelToken = null;
window.lastValue = null;

window.platform_email_validator = function(element, options) {
    var typingTimer;
    
    element.onblur = (e) => {
        options.e = e;
        value_changed(element, options);
    }

    // Run function when user stops typing in input for 500ms
    element.onkeyup = (e) => {
        clearTimeout(typingTimer);

        if (element.value) {
            typingTimer = setTimeout(() => {
                options.e = e;
                value_changed(element, options);
            }, 500);
        }
    }
};

function value_changed(element, options) {
    // Trim string and autocorrect whitespace issues
    var elementValue = element.value.trim();
    element.value = elementValue;

    // Prevent duplicate requests
    if (elementValue === window.lastValue) {
        return;
    }
    window.lastValue = elementValue;

    run_validator(elementValue, options, element);
}

function run_validator(address_text, options, element) {
    // Abort existing AJAX Request to prevent flooding
    if (window.cancelToken !== null) {
        window.cancelToken.cancel();
    }

    // Don't run validator without input
    if (!address_text) {
        return;
    }

    // Validator is in progress
    if (options && options.in_progress) {
        options.in_progress(options.e);
    }

    // Don't run duplicate calls
    if (element.platformValidatorLastSuccessReturn && address_text === element.platformValidatorLastSuccessReturn.address) {
        if (options && options.success) {
            options.success(element.platformValidatorLastSuccessReturn, options.e);
        }

        return;
    }

    // length and @ syntax check
    var error_message = false;
    if (address_text.length > 512) {
        error_message = 'Email address exceeds maximum allowable length of 512 characters.';
    } else if (address_text.split('@').length > 2) {
        error_message = 'Email address must contain only one @.';
    }

    if (error_message) {
        if (options && options.error) {
            options.error(error_message, options.e);
        }

        return;
    }

    // Timeout in case of some kind of internal server error
    var timeoutID = setTimeout(function() {
        error_message = 'Error occurred, unable to validate address.';
        // Abort existing AJAX Request for a true timeout
        if (window.cancelToken !== null) {
            window.cancelToken.cancel();
        }

        if (options && options.error) {
            options.error(error_message, options.e);
        }
    }, 30000); // 30 seconds

    // make ajax call to get validation results
    window.cancelToken = axios.CancelToken.source();
    axios.get('/api/v3/email/validate', { params: { email: address_text }, cancelToken: window.cancelToken.token })
    .then((data) => {
        window.cancelToken = null;
        clearTimeout(timeoutID);
        
        element.platformValidatorLastSuccessReturn = data.data;
        if (options && options.success) {
            options.success(data.data, options.e);
        }
    })
    .catch(() => {
        window.cancelToken = null;
        clearTimeout(timeoutID);
        error_message = 'Error occurred, unable to validate address.';

        if (options && options.error) {
            options.error(error_message, options.e);
        }
    });
}
