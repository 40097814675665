var emailInput = document.getElementById('email');
var form = emailInput.form;
var formSubmit = form.querySelector('button[type="submit"]');

window.platform_email_validator(emailInput, {
    in_progress: validation_in_progress,
    success: validation_success,
    error: validation_error,
});

function handleFormSubmit(e) {
    e.preventDefault();
        
    var el = document.createElement('input');
    el.setAttribute('type', 'hidden');
    el.setAttribute('id', 'form-clicked');
    el.value = 'true';
    emailInput.parentNode.insertBefore(el, emailInput.nextSibling);

    if (emailInput === null || emailInput.value.length === 0 || form.dataset.skipValidation === 'true') {
        validateAndSubmit();
    }

    emailInput.blur();
}

form.addEventListener('submit', handleFormSubmit);

// while the lookup is performing
function validation_in_progress() {
    if (document.getElementById('mailcheck-suggestion') !== null) {
        document.getElementById('mailcheck-suggestion').remove();
    }

    formSubmit.setAttribute('disabled', 'disabled');
}

// if email successfully validated
function validation_success(data) {
    var message = get_suggestion_str(data['is_valid'], data['did_you_mean']);
    var el = document.createElement('div');
    el.setAttribute('id', 'mailcheck-suggestion');
    el.style.display = message === '' ? 'none' : 'block';
    el.innerHTML = message;
    emailInput.parentNode.insertBefore(el, emailInput.nextSibling);
}

// if email is invalid
function validation_error() {
    formSubmit.removeAttribute('disabled');
}

// suggest a valid email
function get_suggestion_str(is_valid, alternate) {
    if (is_valid) {
        var suggestion = '';
        
        if (alternate) {
            suggestion = 'Did you mean <a href="#">' + alternate + '</a>?';
        }
        
        if (document.getElementById('form-clicked') !== null) {
            validateAndSubmit();
            formSubmit.setAttribute('disabled', 'disabled');
        } else {
            form.removeEventListener('submit', handleFormSubmit);
            form.addEventListener('submit', validateAndSubmit);
            formSubmit.removeAttribute('disabled');
        }

        return suggestion;
    }

    if (alternate) {
        return 'This email is invalid. Did you mean <a href="#">' + alternate + '</a>?';
    }
    
    return 'This email is invalid.';
}

function validateAndSubmit(e = null) {
    if (e) {
        e.preventDefault();
    }
  
    form.removeEventListener('submit', handleFormSubmit);
    form.removeEventListener('submit', validateAndSubmit);
  
    // Validate reCAPTCHA if present
    if (typeof grecaptcha !== 'undefined') {
        const recaptcha_key = document.querySelector('meta[name="recaptcha-key"]').getAttribute('content');
        const landing_id = document.querySelector('meta[name="landing-id"]').getAttribute('content');
  
        grecaptcha.ready(function () {
          grecaptcha.execute(recaptcha_key, { action: 'submit_landing_' + landing_id }).then(function (token) {
            var input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', 'recaptcha_token');
            input.setAttribute('value', token);
            form.appendChild(input);
            form.submit();
          });
        });
    } else {
        form.submit();
    }
}
